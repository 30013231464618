<template lang="pug">
  .table-w
    el-table(
      :data="tableData"
      row-class-name="t-tr"
      header-row-class-name="t-header"
      style="width: 100%"
      class="customer-table"
      size="small"
      @row-click="rowClick"
    )
      template(v-for="(item,index) in columns")
        el-table-column(v-if="item.prop" :prop="item.prop" :width="item.width" :min-width="item.minWidth" :label="item.label")
        el-table-column(v-if="item.slot==='action'" :label="item.label" :width="item.width || labelWidth" :min-width="item.minWidth")
          template(slot-scope="scope")
            .action-box
              slot(name='action' v-bind:row="scope.row")
        el-table-column(v-if="item.slot && item.slot!=='action'" :width="item.width" :min-width="item.minWidth" :label="item.label" :className="item.className || item.slot")
          template(slot-scope="scope")
            .cell-slot
              slot(:name="item.slot" v-bind:row="scope.row")
    el-pagination(
      v-if="paginationShow"
      background
      layout="prev, pager, next"
      :total="total"
      :hide-on-single-page="true"
      class="my-pagination"
      @current-change="onCurrentPage"
    )            
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    labelWidth: {
      type: Number,
      default: 120,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    paginationShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCurrentPage(value) {
      this.$emit("onCurrentPage", value);
    },
    rowClick(rowData) {
      this.$emit("rowClick", rowData);
    },
    // onNext(value) {
    //   this.$emit("onNext", value);
    // },
    // onPrev() {
    //   this.$emit("onPrev", value);
    // },
  },
};
</script>

<style lang="less" scoped>
.my-pagination {
  text-align: center;
  margin-top: 1.25rem;
  /deep/ .el-icon-more {
    background: transparent !important;
    color: #5a86d3 !important;
  }
  /deep/ .el-icon-d-arrow-left,
  /deep/ .el-icon-d-arrow-right,
  /deep/ .btn-prev,
  /deep/ .btn-next,
  /deep/ .number {
    background: #050e59 !important;
    color: #5a86d3 !important;
  }
  /deep/ .number:hover,
  /deep/ .active {
    background: url("../assets/image/button2_hover.png") !important;
    color: #fff !important;
    background-size: 100% 100% !important;
  }
}
.customer-table {
  &::before {
    display: none;
  }
}
.action-box {
  .df;
  .jcc;
}
// 滚动条的宽度
// /deep/ .el-table__body-wrapper::-webkit-scrollbar {
//   width: 6px; // 横向滚动条
//   height: 6px; // 纵向滚动条 必写
//   position: absolute;
// }
// // 滚动条的滑块
// /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
//   background-color: rgba(28, 42, 117, 0.2);
//   border-radius: 3px;
//   position: absolute;
// }
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: rgba(42, 59, 158, 0.3);
}
/deep/ .el-table {
  background: transparent;
}
/deep/ .t-tr {
  background: transparent;
  td {
    background: transparent;
    font-size: 14px;
    color: #5a86d3;
    text-align: center;
    border: 1px solid #1c2a75;
  }
  td:not(:first-child) {
    border-left: none;
  }
}
/deep/ .t-header {
  th {
    background: #142166;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border: none;
  }
}
/deep/ .el-table__body {
  // border-spacing: 0 !important;
  // border-collapse: collapse !important;
  .t-tr:not(:first-child) {
    td {
      border-top: none;
    }
  }
}
/deep/ .el-table--border,
/deep/ .el-table--group {
  border: none;
}
/deep/ .el-table--small td, .el-table--small th {
    padding: 3px;
}
</style>
