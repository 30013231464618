<template lang="pug">
  .index-c-w
    .main
      MTitleI(title="保单详情" className="my-title")
      GeminiScrollbar(class="my-scroll-bar" autoshow)
        .info-list(v-if='underwriteDetail')
          .list-item
            .title 保单信息
            .info-box
              .top
                .top-l
                  .info-item
                    .i-label 保单号：
                    Box3(className="my-box3 w25" cornerSize="10px")
                      <el-input class="my-input" v-model="underwriteDetail.policyNo" :readonly="userInfo.readyOnly != 1" placeholder="请输入保单号"></el-input>
                  .info-item
                    .i-label 所属市残联：
                    Box3(className="my-box3 w25" cornerSize="10px")
                      el-select(class="my-select" @change="onFederation" v-model="underwriteDetail.cityFederationId" :disabled="userInfo.readyOnly != 1" placeholder="请选择")
                        el-option(v-for="item in disabilityfederationList" :key="item.federationId" :label="item.federationName" :value="item.federationId")
                  .info-item
                    .i-label 投保人：
                    Box3(className="my-box3 w25" cornerSize="10px")
                      el-select(class="my-select" v-model="underwriteDetail.districtFederationId" :disabled="userInfo.readyOnly != 1" placeholder="请选择")
                        el-option(v-for="item in districtfederationList" :key="item.federationId" :label="item.federationName" :value="item.federationId")
                  .info-item
                    .i-label 出单公司：
                    Box3(className="my-box3 w25" cornerSize="10px")
                      el-select(class="my-select" v-model="underwriteDetail.provinceCompanyId" :disabled="userInfo.readyOnly != 1" placeholder="请选择")
                        el-option(v-for="item in insurancecompanyList" :key="item.companyId" :label="item.companyName" :value="item.companyId")
                  .info-item
                    .i-label 出单时间：
                    Box3(className="my-box3 w25" cornerSize="10px")
                      <el-date-picker class="my-datetime-picker" v-model="underwriteDetail.inssueTime" value-format="yyyy-MM-dd HH:mm:ss" :readonly="userInfo.readyOnly != 1" :clearable="false" type="datetime" placeholder="选择日期时间">
                      </el-date-picker>
                  .info-item
                    .i-label 保障期限：
                    Box3(className="my-box3 my-time" cornerSize="10px")
                      <el-date-picker class="my-datetime-picker" value-format="yyyy-MM-dd HH:mm:ss" v-model="underwriteDetail.insuranceStartTime" :readonly="userInfo.readyOnly != 1" :clearable="false" type="datetime" placeholder="选择日期时间">
                      </el-date-picker>
                    .sp-line
                    Box3(className="my-box3 my-time" cornerSize="10px")
                      <el-date-picker class="my-datetime-picker" value-format="yyyy-MM-dd HH:mm:ss" v-model="underwriteDetail.insuranceEndTime" :readonly="userInfo.readyOnly != 1" type="datetime" :clearable="false" placeholder="选择日期时间">
                      </el-date-picker>
                .top-r
                  .info-item
                    .i-label 初始投保人数：
                    Box3(className="my-box3 w21" cornerSize="10px")
                      <el-input class="my-input" v-model="underwriteDetail.initPeopleCounts" :readonly="userInfo.readyOnly != 1" placeholder="请输入初始投保人数"></el-input>
                  .info-item
                    .i-label 批增投保人数：
                    Box3(className="my-box3 w21" cornerSize="10px")
                      <el-input class="my-input" v-model="underwriteDetail.addPeopleCounts" :readonly="userInfo.readyOnly != 1" placeholder="请输入批增投保人数"></el-input>
                  .info-item
                    .i-label 保险方案：
                    Box3(className="my-box3 w21" cornerSize="10px")
                      el-select(class="my-select" @change="onInsurancePlan" v-model="insurancePlan.insurancePlanType" :disabled="userInfo.readyOnly != 1" placeholder="请选择")
                        el-option(v-for="item in insurancePlanTypeList" :key="item.value" :label="item.label" :value="item.value")
                  .info-item
                    .i-label 保费单价：
                    Box3(className="my-box3 w21" cornerSize="10px")
                      <el-input class="my-input" v-model="underwriteDetail.premiumUnitPrice" :readonly="userInfo.readyOnly != 1" placeholder="请输入保费单价"></el-input>
                    .unit 元/人
                  .info-item
                    .i-label 总保费：
                    Box3(className="my-box3 w21" cornerSize="10px")
                      <el-input class="my-input" v-model="underwriteDetail.premium" :readonly="userInfo.readyOnly != 1" placeholder="请输入总保费"></el-input>
                    .unit 元
              .de-bottom
                .i-label 保险方案内容：
                MyTable2(
                  v-if='insurancePlanType!=="0" && insurancePlan.insurancePlanType!=="0"'
                  :tableData="insurancePlan.insuranceContentList"
                  :columns="columns"
                )
                MyTable2(
                  v-if='insurancePlanType==="0" || insurancePlan.insurancePlanType==="0"'
                  :tableData="insurancePlan.insuranceContentList"
                  :columns="columns1"
                )
                  <template v-slot:insuredAmount="{row}">
                    Box3(className="my-box3 w25")
                      <el-input class="my-input" v-model="row.insuredAmount" :readonly="userInfo.readyOnly != 1" placeholder=""></el-input>
                    //- span.unit-t 万元
                  </template>
              .de-bottom(style="margin-top: 10px;")
                .i-label 人员信息：
                MyTable2(
                  :tableData="peopleList"
                  :columns="columns2"
                  :paginationShow="true"
                  :total="pagation.total"
                  @onCurrentPage="onCurrentPage"
                )
                  <template v-slot:sex="{row}">
                      <span>{{ row.sex === '0' ? '男' : '女' }}</span>
                  </template>
      .back
        .cur-btn.goBack(@click="onBack") 返回
        .cur-btn(@click="onSave" v-if="userInfo.readyOnly == 1") 保存
</template>

<script>
import Box3 from "../../components/Box3.vue";
import MyTable2 from "../../components/MyTable2.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      datatime: "",
      datatime1: "",
      datatime2: "",
      insurancePlanType: "", //保险方案
      //保险方案 option
      insurancePlanTypeList: [
        { label: "待续转", value: "0" },
        { label: "方案一", value: "1" },
        { label: "方案二", value: "2" },
        { label: "方案三", value: "3" },
        { label: "方案四", value: "4" },
      ],
      //表头
      columns: [
        {
          label: "保障内容",
          prop: "insuranceContentName",
          width: "136px",
          align: "left",
        },
        { label: "保额", prop: "insuredAmount" },
      ],
      //表头
      columns1: [
        {
          label: "保障内容",
          prop: "insuranceContentName",
          width: "136px",
          align: "left",
        },
        { label: "保额", slot: "insuredAmount" },
      ],
      //表头
      columns2: [
        {
          label: "序号",
          prop: "index"
        },
        { label: "姓名", prop: "name" },
        { label: "身份证", prop: "idCard" },
        { label: "性别", slot: "sex" },
      ],
      //table 数据
      tableData: [],
    };
  },
  components: {
    Box3,
    MyTable2,
  },
  watch: {
    underwriteDetail(newVal) {
      //获取区县残联
      this.getDistrictfederationListAction({
        parentId: newVal.cityFederationId,
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      underwriteDetail: (state) => state.underwritingCenter.underwriteDetail, //承保详情
      pagation: (state) => state.underwritingCenter.pagation,
      insurancePlan: (state) => state.underwritingCenter.insurancePlan, //保险方案
      peopleList: (state) => state.underwritingCenter.peopleList, //人员信息
      //客户让不显示parentFederationId=0的那个
      disabilityfederationList: (state) =>
        state.app.disabilityfederationList.filter(
          (e) => e.parentFederationId != 0
        ),
      insurancecompanyList: (state) => state.app.insurancecompanyList, //保险公司
      districtfederationList: (state) => state.app.districtfederationList, //区县残联
    }),
  },
  async created() {
    let underwriteId = this.$route.query.id; //获取list id
    this.getUnderwriteDetailAction({ underwriteId }); //获取承保中心详情
    this.getPageListbyparentAction({ parentId: "1", pageSize: 1000 }); //获取市残联
    this.getInsurancecompanyListAction({ parentId: "0" }); //获取保险公司列表
    this.onSearch(); // 人员列表
  },
  methods: {
    ...mapActions([
      "underwriteUpdateAction",
      "getInsurancePlanAction",
      "getUnderWritePeopleListAction",
      "getDistrictfederationListAction",
      "getUnderwriteDetailAction",
      "getPageListbyparentAction",
      "getInsurancecompanyListAction",
    ]),
    //保险方案选择时回调
    onInsurancePlan(value) {
      this.insurancePlanType = value;
      this.getInsurancePlanAction({ insurancePlanType: value });
      if (value === "0") {
        this.underwriteDetail.dataType = "0";
      } else {
        this.underwriteDetail.dataType = "1";
      }
    },
    //返回按钮回调
    onBack() {
      this.$router.push({ path: "/underwriting_center/list" });
    },
    //市残联选择时，获取区县残联
    onFederation(value) {
      this.getDistrictfederationListAction({
        parentId: value,
      });
      this.underwriteDetail.districtFederationId = null;
    },
    async onSave() {
      let _this = this;
      //后台是投保人是根据名称存的，所以名称要根据id变，再给后台才行
      this.districtfederationList.forEach((element) => {
        if (
          this.underwriteDetail.districtFederationId == element.federationId
        ) {
          this.underwriteDetail.applicantName = element.federationName;
        }
      });
      //同样的
      this.disabilityfederationList.forEach((element) => {
        if (this.underwriteDetail.cityFederationId == element.federationId) {
          this.underwriteDetail.belongCityDps = element.federationName;
        }
      });
      let res = await this.underwriteUpdateAction({
        underwrite: this.underwriteDetail,
        insurancePlan: {
          insurancePlanName: this.insurancePlanTypeList[
            Number(this.insurancePlan.insurancePlanType)
          ].label,
          insurancePlanType: this.insurancePlan.insurancePlanType,
          insuranceContentList: this.insurancePlan.insuranceContentList,
        },
      });
      if (res.result) {
        this.$message({
          message: "保存成功",
          type: "success",
          onClose: function() {
            _this.onBack();
          },
        });
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    //分页改变
    onCurrentPage(currentPage) {
      this.onSearch(currentPage);
    },
    //搜索按钮回调
    onSearch(page) {
      let params = {
        currentPage: page ? page : 1,
        pageSize: 10,
        underWriteId: this.$route.query.id,
      };
      this.getUnderWritePeopleListAction(params);
    },
    /*onBack() {
      this.$router.go(-1);
    },*/
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-icon-arrow-up:before {
  content: "\e6e1";
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
/deep/ .popper__arrow,
/deep/ .el-select-dropdown__empty {
  display: none !important;
}
/deep/ .insuredAmount {
  /deep/ .cell-slot {
    display: flex;
    align-items: center;
  }
}
.unit-t {
  padding: 0 10px;
}
.my-datetime-picker {
  width: 100%;
}
.my-box3.w25 {
  width: 25rem !important;
  height: 32px;
  display: inline-block;
}
.my-box3.w21 {
  width: 21rem !important;
  height: 32px;
  display: inline-block;
}
.my-box3.my-time {
  width: 13rem!important;
}
.sp-line {
  height: 1.5px;
  width: 10px;
  background: #4369bc;
  margin: 0 6px;
}
.main {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
  padding: 20px;
  .df;
  .dfc;
  .my-title {
    padding-bottom: 20px;
  }
  .back {
    text-align: center;
    padding: 20px 0 0 0;
    .cur-btn {
      display: inline-block;
    }
  }
  .info-list {
    flex: 1;
    .i-label {
      width: 120px;
      text-align: right;
    }
    .unit {
      padding-left: 10px;
    }
    .list-item {
      // background: #091653;
      width: 100%;
      height: 100%;
      .df;
      .dfc;
      .title {
        background: rgba(20, 33, 102, 0.8);
        height: 36px;
        line-height: 36px;
        color: #fff;
        font-size: 14px;
        padding: 0 20px;
      }
      .info-box {
        flex: 1;
        color: #5a86d3;
        line-height: 24px;
        padding: 10px 0;
        border: 1px solid #1c2a75;
        border-top: none;
        .top {
          .df;
        }
      }
      .info-item {
        .df;
        .dfac;
        padding: 0 0 10px 0;
      }
      .top-l {
        flex: 1;
        padding-left: 64px;
      }
      .top-r {
        flex: 1.2;
        .info-item {
          .i-label {
            width: 120px;
          }
        }
      }
      .de-bottom {
        width: 100%;
        padding-left: 60px;
        padding-right: 10rem;
        .df;
        .i-label {
          width: 140px;
        }
        & > div:last-child {
          width: 100%;
        }
        /deep/ td .cell {
          text-align: center;
        }
      }
    }
  }
}
/deep/ .el-input__inner {
  background-color: #101350 !important;
}
.cur-btn.goBack {
  color: #318ef0;
  border: 0.0625rem solid #318ef0;
  border-radius: 0.125rem;
  background: transparent;
  margin-right: 30px;
}
.cur-btn.goBack:hover {
  color: #318ef0;
  border: 0.0625rem solid #318ef0;
  background: rgba(65, 105, 225, 0.3);
}
</style>
